import axios from 'axios'
import config from "@/config/config";
import router from '../router/index';

const excluedUrlArr = ['/v1','/v1/login','/v1/user/app/register']; //不添加token的url白名单
const request = axios.create({
    baseURL: config.baseUrl,
    timeout: 120000,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
    }
})

// 请求拦截器
request.interceptors.request.use(
    config => {
        // 添加请求头等前置处理
       // console.log('config--',config);
        if(excluedUrlArr.indexOf(config.url) <0){
            config.headers['Authorization'] = 'Bearer '+localStorage.getItem('token')
        }
        return config
    },
    error => {
        // 请求错误处理
        console.log('Request Error:', error)
        return Promise.reject(error)
    }
)

// 响应拦截器
request.interceptors.response.use(
    response => {
        // 响应后处理
        console.log('success====');
        console.log(response);
        if (response.status === 200) {
            if(response.data.success){
                return Promise.resolve(response.data.data);
            }else{
                if(!response.config.hideMessage){
                    ElMessage(response.data.error);
                }
                return Promise.resolve({'error':response.data.error});
            }
        } else {
            return Promise.reject(response.data)
        }
    },
    error => {
        console.log('Response Error:', error)
        if( error.response && error.response.status == 401){
            router?.push('/login');
        }else if(error.response && error.response.status == 403){
            ElMessage('接口无权限访问');
        }else{
            return Promise.reject(error)
        }

    }
)

/*
 *  文件上传
 *  url:请求地址
 *  params:参数
 * */
export function fileUpload(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.post(config.baseUrl + url, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(response =>{
            resolve(response.data);
        }).catch(error =>{
            reject(error);
        })
    });
}

export default request;


