import request,{fileUpload} from '../utils/request';

//获取购物车信息
export function getCartDetail() {
    return request({
        url: `/v1/cart/detail/list`,
        method: 'get',
    })
}
//获取购物车数量
export function getCartCount() {
    return request({
        url: `/v1/cart/detail/listCount`,
        method: 'get',
    })
}

//获取商品分类
export function getCategoryByPid(pid) {
    return request({
        url: `/v1/forest/getCategoryByPid/${pid}`,
        method: 'get',
    })
}
//获取商品明细
export function getAllCategoryByCid(pid) {
    return request({
        url: `/v1/forest/getAllCategoryByCid/${pid}`,
        method: 'get',
    })
}
//获取商品规格
export function getAllPropByCid(pid) {
    return request({
        url: `/v1/forest/getAllPropByCid/${pid}`,
        method: 'get',
    })
}
//首页数据
export function getHomeData() {
    return request({
        url: `/v1`,
        method: 'get',
    })
}
//现货交易
export function queryItems(data) {
    return request({
        url: '/v1/item/searchDb',
        method: 'post',
        data
    })
}
//获取信息咨询左侧菜单
export function getArticleMore(param) {
    return request({
        url: `/v1/news/article/more?columnId=${param}&pageNum=1&pageSize=20`,
        method: 'get',
    })
}
//获取相应类目下的信息咨询
export function getArticleByColumnId(param) {
    return request({
        url: `/v1/news/article/listByColumnId?columnId=${param.columnId}&pageNum=${param.currentPage}&pageSize=${param.pageSize}`,
        method: 'get',
    })
}
//获取文章详情
export function getArticleDetail(param) {
    return request({
        url: `/v1/news/article/detail?articleId=${param}`,
        method: 'get',
    })
}
//获取消息数量
export function getMessageCount(params) {
    return request({
        url: `/v1/stationMessage/count`,
        method: 'get',
        params
    })
}
//图片上传
export function uploadFiles(params) {
    return fileUpload('/v1/image/uploadImages',params);
}

//获取token
export function getToken(params) {
    return request({
        url: `/api/blade-auth/token`,
        method: 'post',
        params
    })
}

