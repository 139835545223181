import request from '../utils/request';

//获取个人中心
export function getPersonCenter() {
    return request({
        url: `/v1/user/center`,
        method: 'get',
    })
}
//获取商品规格
export function getCategorySpec(pid) {
    return request({
        url: `/v1/forest/getCategorySpec/${pid}`,
        method: 'get',
    })
}
//发布商品
export function publicItem(data) {
    return request({
        url: '/v1/item/admin/preview',
        method: 'post',
        data
    })
}
//查询商品
export function queryGoods(data) {
    return request({
        url: '/v1/item/listGoodsDetail',
        method: 'post',
        data
    })
}
//上架商品
export function upGoods(data) {
    return request({
        url: '/v1/item/upshelfGoodsList',
        method: 'post',
        data
    })
}
//删除商品
export function delGoods(data) {
    return request({
        url: '/v1/item/deleteGoodsList',
        method: 'post',
        data
    })
}
//下架商品
export function downShelfGoods(data) {
    return request({
        url: '/v1/item/downshelfGoodsList',
        method: 'post',
        data
    })
}
//获取前台menu列表信息
export function getUserMenuShow() {
    return request({
        url: `/v1/user/menu/show`,
        method: 'post',
    })
}
//获取用户信息
export function getUserInfo(pid) {
    return request({
        url: `/v1/user/center`,
        method: 'get',
    })
}
//获取企业信息
export function getUserCompany(pid) {
    return request({
        url: `/v1/user/company`,
        method: 'get',
    })
}
//获取企业下属会员
export function getUserListByComId(data) {
    return request({
        url: `/v1/user/list`,
        method: 'post',
        data
    })
}
//修改密码
export function changeUserPassword(data) {
    return request({
        url: `/v1/user/password`,
        method: 'POST',
        data
    })
}
//获取相应状态的采购订单
export function getBuyerOrderByStatus(data) {
    return request({
        url: `/v1/order/list`,
        method: 'POST',
        data
    })
}
//获取订单详情
export function getOrderDetail(type,orderId) {
    return request({
        url: `/v1/order/manager/detail/${type}/${orderId}`,
        method: 'get',
    })
}
//获取物流详情
export function getLogisticsDetail(orderDetailId) {
    return request({
        url: `/v1/order/transport/transportDetail/${orderDetailId}`,
        method: 'get',
    })
}
//获取电子订单
export function getElecorderDetail(orderId) {
    return request({
        url: `/v1/order/manager/contract/${orderId}`,
        method: 'get',
    })
}
//自主发货or平台发货
export function updateOrderStatus(data) {
    return request({
        url: `/v1/item/updateOrderStatus`,
        method: 'post',
        data
    })
}
//评论
export function submitEvaluate(data) {
    return request({
        url: `/v1/item/updateOrderEvluate`,
        method: 'POST',
        data
    })
}
//下属会员同意
export function agreeBind(data) {
    return request({
        url: `/v1/user/agreeBind`,
        method: 'POST',
        data
    })
}
//下属会员驳回
export function declineBind(data) {
    return request({
        url: `/v1/user/declineBind`,
        method: 'POST',
        data
    })
}
//下属会员冻结
export function freezeUser(data) {
    return request({
        url: `/v1/user/freeze`,
        method: 'POST',
        data
    })
}
//下属会员恢复
export function activeUser(data) {
    return request({
        url: `/v1/user/active`,
        method: 'POST',
        data
    })
}
//添加子会员
export function addCompanyUser(data) {
    return request({
        url: `/v1/company/addUser`,
        method: 'POST',
        data
    })
}
//修改子会员
export function updataCompanyUser(data) {
    return request({
        url: `/v1/company/updateUser`,
        method: 'POST',
        data
    })
}
//获取业务类型数据
export function getbusData(pid) {
    return request({
        url: `/v1/company/addUserPage`,
        method: 'get',
    })
}
//获取消息列表
export function getMessageList(data) {
    console.log('data--',data);
    return request({
        url: `/v1/stationMessage/mylist`,
        method: 'get',
        params: data
    })
}
// 我的站内信数量
export function stationMessageCount(data) {
    console.log('data--',data);
    return request({
        url: `/v1/stationMessage/count`,
        method: 'get',
        params: data
    })
}
export function tradeDone(params) {
    return request({
        url: `/v1/int-order/tradeDone`,
        method: 'get',
        params
    })
}
//设置消息已读
export function setMessageRead(id) {
    return request({
        url: `/v1/stationMessage/read?messageId=${id}`,
        method: 'get',
    })
}
//校验支付凭证
export function receiptCheck(data) {
    return request({
        url: `/v1/pay/receiptCheck`,
        method: 'post',
        data
    })
}
//上传支付凭证
export function receiptAdd(data) {
    return request({
        url: `/v1/pay/add`,
        method: 'post',
        data
    })
}
//催上传支付凭证
export function urgeOrderPay(id) {
    return request({
        url: `/v1/pay/urge/${id}`,
        method: 'get',
    })
}
//获取电子合同
export function getOrderContract(id) {
    return request({
        url: `/v1/orderContract/${id}`,
        method: 'get',
    })
}
//获取物流发布的内容
export function getTransportContent(orderDetailId) {
    return request({
        url: `/v1/order/transport/add?orderDetailId=${orderDetailId}`,
        method: 'get',
    })
}
//发布物流询价单
export function commitInquiry(data) {
    return request({
        url: `/v1/order/transport/commitInquiry`,
        method: 'post',
        data
    })
}
//新增收货人
export function addReceiver(data) {
    return request({
        url: `/v1/receiver/add`,
        method: 'post',
        data
    })
}
//获取报价单列表
export function quoteList(orderDetailId) {
    return request({
        url: `/v1/order/transport/quoteList?orderDetailId=${orderDetailId}`,
        method: 'get',
    })
}
//确定某个报价
export function confirmQuote(quoteId,orderDetailId) {
    return request({
        url: `/v1/order/transport/choose?quoteId=${quoteId}&orderDetailId=${orderDetailId}`,
        method: 'get',
    })
}
//意向单列表
export function intOrderList(data) {
    return request({
        url: `/v1/int-order/list`,
        method: 'post',
        data
    })
}
//意向单下单
export function intBuySuccess(data) {
    return request({
        url: `/v1/int-order/buySuccess`,
        method: 'post',
        data
    })
}
//获取物流轨迹
export function getTrail(orderDetailId) {
    return request({
        url: `/v1/order/transport/vehicleTracks/${orderDetailId}`,
        method: 'get',
    })
}
//物流公司列表
export function getLogisticsCompany() {
    return request({
        url: `/v1/order/transport/logisticsList`,
        method: 'get',
    })
}
//整个订单指定物流公司发货
export function orderTransport(data) {
    return request({
        url: `/v1/order/transport/orderTransport`,
        method: 'post',
        data
    })
}
// 意向单详情
export function orderDetail(id) {
    return request({
        url: `/v1/xcx-order/${id}`,
        method: 'get'
    })
}

// 经销商列表
export function contractorListApi(params) {
    return request({
        url: `/v1/contractor/list`,
        method: 'get',
        params
    })
}
// 项目列表
export function nowProjectApi(params) {
    return request({
        url: `/v1/xcx-order/nowProject`,
        method: 'get',
        params
    })
}
// 商品列表
export function searchDbItemApi(data) {
    return request({
        url: `/v1/item/searchDb`,
        method: 'post',
        data
    })
}
// 获取采购品类
export function getAllCategoryByCidApi(pid) {
    return request({
        url: `/v1/forest/getAllCategoryByCid/${pid}`,
        method: 'get',
    })
}
// 收货人列表
export function receiverListApi() {
    return request({
        url: `/v1/receiver/list`,
        method: 'get',
    })
}
// 保存意向单
export function saveIntentLetter(data) {
    return request({
        url: '/v1/xcx-order/save',
        method: 'post',
        data
    })
}
// 发送验证码
export function sendText(data) {
    return request({
        url: '/v1/sms/sendVerifyCode',
        method: 'post',
        data
    })
}
// 发布意向单
export function publishIntentLetter(data) {
    return request({
        url: '/v1/xcx-order/publish',
        method: 'post',
        data
    })
}

// 修改商品价格
export function submitPriceForGoods(data) {
    return request({
        url: '/v1/item/price/submitNewPrice',
        method: 'post',
        data
    })
}

// 获取商品价格修改记录
export function getGoodsPriceRecordList(data) {
    return request({
        url: '/v1/item/price/priceRecordList',
        method: 'get',
        params: data
    })
}