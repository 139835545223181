import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-15641e06"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wrapper"
};
const _hoisted_2 = {
  class: "hor"
};
import { useRouter, useRoute } from 'vue-router';
import { ref } from "vue";
import LeftTab from '../components/personCenter/LeftTab';
import CenterMain from '../components/personCenter/CenterMain';
import Publish from '../components/personCenter/Publish';
import SaleGood from '../components/personCenter/ShelfGood';
import ReleasedGood from '../components/personCenter/ReleasedGood';
import PurchaseOrder from '../components/personCenter/PurchaseOrder';
import SaleOrder from '../components/personCenter/SaleOrder';
import CompanyInfo from '../components/personCenter/CompanyInfo';
import SubMember from '../components/personCenter/SubMember';
import ModifyPassword from '../components/personCenter/ModifyPassword';
import OrderDetail from '../components/personCenter/OrderDetail';
import LogisticsDetail from '../components/personCenter/LogisticsDetail';
import UploadPayment from '../components/personCenter/UploadPayment';
import MessageList from '../components/personCenter/MessageList';
import LogisticsRelease from '../components/personCenter/LogisticsRelease';
import ChooseOffer from '../components/personCenter/ChooseOffer';
import Footer from "@/components/home/Footer";
import IntentOrder from '../components/personCenter/IntentOrder';
import ChooseLogistics from '../components/personCenter/ChooseLogistics';
import config from "@/config/config";
export default {
  __name: 'PersonCenter',
  setup(__props) {
    const router = useRouter();
    const route = useRoute();
    const activeKey = ref(route.query.key || 'center');
    const orderId = ref('');
    const clickItem = ref('');
    const goods = ref([]);
    const leftClickTab = (key, id, cargos, item) => {
      console.log('key--', item);
      activeKey.value = key;
      orderId.value = id;
      goods.value = cargos;
      clickItem.value = item;
    };
    const clickTab = index => {
      console.log('index--', index);
      if (index == 1) {
        router.push('/home');
      } else if (index == 2) {
        router.push('/trade');
      } else if (index == 4) {
        window.open(config.wuliuBaseUrl, '_blank');
      }
    };
    return (_ctx, _cache) => {
      const _component_Header = _resolveComponent("Header");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Header, {
        onTabFn: clickTab
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_unref(LeftTab), {
        onLeftTabFn: leftClickTab
      }), activeKey.value == 'center' ? (_openBlock(), _createBlock(_unref(CenterMain), {
        key: 0
      })) : _createCommentVNode("", true), activeKey.value == 'publish' ? (_openBlock(), _createBlock(_unref(Publish), {
        key: 1
      })) : _createCommentVNode("", true), activeKey.value == 'shelf' ? (_openBlock(), _createBlock(_unref(SaleGood), {
        key: 2
      })) : _createCommentVNode("", true), activeKey.value == 'released' ? (_openBlock(), _createBlock(_unref(ReleasedGood), {
        key: 3
      })) : _createCommentVNode("", true), activeKey.value == 'purchase' ? (_openBlock(), _createBlock(_unref(PurchaseOrder), {
        key: 4,
        onLeftTabFn: leftClickTab
      })) : _createCommentVNode("", true), activeKey.value == 'sale' ? (_openBlock(), _createBlock(_unref(SaleOrder), {
        key: 5,
        onLeftTabFn: leftClickTab
      })) : _createCommentVNode("", true), activeKey.value == 'company' ? (_openBlock(), _createBlock(_unref(CompanyInfo), {
        key: 6
      })) : _createCommentVNode("", true), activeKey.value == 'member' ? (_openBlock(), _createBlock(_unref(SubMember), {
        key: 7
      })) : _createCommentVNode("", true), activeKey.value == 'password' ? (_openBlock(), _createBlock(_unref(ModifyPassword), {
        key: 8,
        onLeftTabFn: leftClickTab
      })) : _createCommentVNode("", true), activeKey.value == 'orderDetail' ? (_openBlock(), _createBlock(_unref(OrderDetail), {
        key: 9,
        orderId: orderId.value
      }, null, 8, ["orderId"])) : _createCommentVNode("", true), activeKey.value == 'logisticsDetail' ? (_openBlock(), _createBlock(_unref(LogisticsDetail), {
        key: 10,
        orderDetailId: orderId.value
      }, null, 8, ["orderDetailId"])) : _createCommentVNode("", true), activeKey.value == 'uploadPayment' ? (_openBlock(), _createBlock(_unref(UploadPayment), {
        key: 11,
        orderId: orderId.value
      }, null, 8, ["orderId"])) : _createCommentVNode("", true), activeKey.value == 'message' ? (_openBlock(), _createBlock(_unref(MessageList), {
        key: 12
      })) : _createCommentVNode("", true), activeKey.value == 'logisticsRelease' ? (_openBlock(), _createBlock(_unref(LogisticsRelease), {
        key: 13,
        orderDetailId: orderId.value
      }, null, 8, ["orderDetailId"])) : _createCommentVNode("", true), activeKey.value == 'chooseOffer' ? (_openBlock(), _createBlock(_unref(ChooseOffer), {
        key: 14,
        orderDetailId: orderId.value
      }, null, 8, ["orderDetailId"])) : _createCommentVNode("", true), activeKey.value == 'intent' ? (_openBlock(), _createBlock(_unref(IntentOrder), {
        key: 15,
        onLeftTabFn: leftClickTab
      })) : _createCommentVNode("", true), activeKey.value == 'chooseLogistics' ? (_openBlock(), _createBlock(_unref(ChooseLogistics), {
        key: 16,
        orderId: orderId.value,
        goods: goods.value,
        onLeftTabFn: leftClickTab,
        clickItem: clickItem.value
      }, null, 8, ["orderId", "goods", "clickItem"])) : _createCommentVNode("", true)])]), _createVNode(_unref(Footer))], 64);
    };
  }
};