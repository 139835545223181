import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6c9d457c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wrapper"
};
const _hoisted_2 = {
  class: "hor"
};
const _hoisted_3 = {
  class: "right"
};
const _hoisted_4 = {
  class: "title"
};
const _hoisted_5 = {
  class: "content"
};
const _hoisted_6 = {
  class: "item"
};
const _hoisted_7 = ["src"];
const _hoisted_8 = {
  class: "title2"
};
const _hoisted_9 = {
  class: "time"
};
const _hoisted_10 = {
  class: "jus"
};
const _hoisted_11 = ["innerHTML"];
const _hoisted_12 = {
  class: "pagination"
};
import { useRouter, useRoute } from 'vue-router';
import { ref, onMounted } from "vue";
import Footer from "../components/home/Footer";
import LeftTab from "../components/infoAdvice/LeftTab";
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import { getArticleMore, getArticleByColumnId } from '../api/home';
import config from "@/config/config";
export default {
  __name: 'InfoClassify',
  setup(__props) {
    const route = useRoute();
    const columnId = ref();
    const columnList = ref();
    const articleList = ref();
    onMounted(async () => {
      let id = route.query.columnId;
      const res = await getArticleMore(id);
      if (res) {
        columnList.value = res.columnList;
        articleList.value = res.articleList;
      }
      columnId.value = id;
      queryData();
    });
    const router = useRouter();
    const clickTab = index => {
      console.log('index--', index);
      if (index == 1) {
        router.push('/home');
      } else if (index == 2) {
        router.push('/trade');
      } else if (index == 4) {
        window.open(config.wuliuBaseUrl, '_blank');
      }
    };
    //左侧菜单栏点击
    const activeKey = ref(route.query.name);
    const title = ref(route.query.name || '会议动态');
    const currentPage = ref(1);
    const pageSize = ref(10);
    const total = ref(0);
    const listData = ref();
    const leftClickTab = (key, id) => {
      console.log('key--', key);
      title.value = key;
      activeKey.value = key;
      columnId.value = id;
      queryData();
    };
    //查询右侧数据
    const queryData = async () => {
      const res = await getArticleByColumnId({
        columnId: columnId.value,
        currentPage: currentPage.value,
        pageSize: pageSize.value
      });
      console.log('res--', res);
      if (res) {
        for (let item of res) {
          if (item.imgUrl) {
            item.imgUrl = config.baseUrl + item.imgUrl;
          } else {
            item.imgUrl = require('../assets/img/pic-alt.png');
          }
        }
        listData.value = res;
      }
    };
    const handleSizeChange = val => {
      pageSize.value = val;
      queryData();
    };
    const handleCurrentChange = val => {
      currentPage.value = val;
      queryData();
    };
    //去文章详情
    const goDetail = id => {
      console.log('文章id---', id);
      router.push({
        path: 'infoDetail',
        query: {
          articleId: id
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_Header = _resolveComponent("Header");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _component_el_config_provider = _resolveComponent("el-config-provider");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Header, {
        onTabFn: clickTab
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [columnList.value ? (_openBlock(), _createBlock(_unref(LeftTab), {
        key: 0,
        onLeftTabFn: leftClickTab,
        articleList: articleList.value,
        columnList: columnList.value,
        activeKey: activeKey.value
      }, null, 8, ["articleList", "columnList", "activeKey"])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, [_createElementVNode("p", _hoisted_4, _toDisplayString(title.value), 1), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(listData.value, item => {
        return _openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("img", {
          src: item.imgUrl,
          class: "itemImg"
        }, null, 8, _hoisted_7), _createElementVNode("div", null, [_createElementVNode("p", null, [_createElementVNode("span", _hoisted_8, _toDisplayString(item.title), 1), _createElementVNode("span", _hoisted_9, _toDisplayString(item.createdAt), 1)]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", {
          class: "desc",
          innerHTML: item.content
        }, null, 8, _hoisted_11), _createVNode(_component_el_button, {
          type: "default",
          class: "detailBtn",
          onClick: $event => goDetail(item.articleId)
        }, {
          default: _withCtx(() => [_createTextVNode("查看详情")]),
          _: 2
        }, 1032, ["onClick"])])])]);
      }), 256))]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_el_config_provider, {
        locale: _unref(zhCn)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_pagination, {
          "current-page": currentPage.value,
          "onUpdate:currentPage": _cache[0] || (_cache[0] = $event => currentPage.value = $event),
          "page-size": pageSize.value,
          "onUpdate:pageSize": _cache[1] || (_cache[1] = $event => pageSize.value = $event),
          "page-sizes": [10, 20, 30],
          small: false,
          disabled: false,
          background: true,
          layout: "sizes, prev, pager, next",
          total: total.value,
          onSizeChange: handleSizeChange,
          onCurrentChange: handleCurrentChange
        }, null, 8, ["current-page", "page-size", "total"])]),
        _: 1
      }, 8, ["locale"])])])])]), _createVNode(_unref(Footer))], 64);
    };
  }
};