import { createRouter,createWebHashHistory } from "vue-router";
import Home from '../views/Index.vue';
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
import Trade from '../views/Trade';
import PersonCenter from '../views/PersonCenter';
import ElectronicOrder from '../views/ElectronicOrder';
import Detail from '../views/Detail';
import ShoppingCart from '../views/ShoppingCart';
import HelpCenter from '../views/HelpCenter';
import InfoAdvice from '../views/InfoAdvice';
import InfoClassify from '../views/InfoClassify';
import InfoDetail from '../views/InfoDetail';
import FindPassword from '../views/FindPassword';
import Invite from '../views/Invite';
import Auth from '../views/Auth';

const routes = [
    {
        path:'/home',
        name:Home,
        component:Home,
        meta:{title:'材料采购平台'}
    },
    {
        path:'/login',
        component:Login,
        meta:{title:'登录'}
    },
    {
        path:'/register',
        component:Register,
        meta:{title:'注册'}
    },
    {
        path:'/auth',
        component:Auth,
        meta:{title:'认证'}
    },
    {
        path:'/invite',
        component:Invite,
        meta:{title:'注册'}
    },
    {
        path:'/findPassword',
        component:FindPassword,
        meta:{title:'找回密码'}
    },
    {
        path:'/trade',
        query:{},
        component:Trade,
        meta:{title:'现货交易'}
    },
    {
        path:'/personCenter',
        query:{},
        component:PersonCenter,
        meta:{title:'会员中心'}
    },
    {
        path:'/electronicOrder',
        query:{},
        component:ElectronicOrder,
        meta:{title:'电子订单'}
    },
    {
        path:'/detail',
        query:{},
        component:Detail,
        meta:{title:'商品详情'}
    },
    {
        path:'/shoppingCart',
        component:ShoppingCart,
        meta:{title:'购物车'}
    },
    {
        path:'/helpCenter',
        component:HelpCenter,
        meta:{title:'帮助中心'}
    },
    {
        path:'/infoAdvice',
        component:InfoAdvice,
        meta:{title:'信息咨询'}
    },
    {
        path:'/infoClassify',
        query:{},
        component:InfoClassify,
        meta:{title:'信息分类'}
    },
    {
        path:'/infoDetail',
        query:{},
        component:InfoDetail,
        meta:{title:'信息详情'}
    },
    {
        path:'/',
        redirect:'/home',
        meta:{title:'材料采购平台'}
    }
];
const router = createRouter({
    history:createWebHashHistory(),
    routes
});
//全局路由守卫
router.beforeEach((to, from, next) => {
   console.log('to----',to);
    if (to.meta.title) { // 判断是否有标题
        document.title = to.meta.title;
    }
   next();
   // if(to?.path == '/about'){
   //     next('/home')
   // }else{
   //     next()
   // }


})
export default router;
