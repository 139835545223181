import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { useRouter } from 'vue-router';
import Footer from "../components/home/Footer";
import config from '../config/config';
export default {
  __name: 'Index',
  setup(__props) {
    const router = useRouter();
    const clickTab = index => {
      console.log('index--', index);
      if (index == 1) {
        router.push('/home');
      } else if (index == 2) {
        router.push('/trade');
      } else if (index == 4) {
        window.open(config.wuliuBaseUrl, '_blank');
      }
    };
    return (_ctx, _cache) => {
      const _component_Header = _resolveComponent("Header");
      const _component_Home = _resolveComponent("Home");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Header, {
        onTabFn: clickTab,
        activeIndex: 1,
        showCategory: "true"
      }), _createVNode(_component_Home), _createVNode(_unref(Footer))], 64);
    };
  }
};