//商品详情
import request from "@/utils/request";

export function getDetail(id) {
    return request({
        url: `/v1/item/detail/${id}`,
        method: 'get',
    })
}
//添加购物车
export function addCart(data) {
    return request({
        url: '/v1/cart/detail/add',
        method: 'post',
        data
    })
}
//单条删除购物车内的商品
export function singleDelCart(data) {
    return request({
        url: `/v1/cart/detail/update?detailId=${data.detailId}&detailStatus=2`,
        method: 'get',
    })
}
//批量删除购物车内的商品
export function batchDelCart(data) {
    return request({
        url: '/v1/cart/detail/batchUpdate',
        method: 'post',
        data
    })
}
//购物车结算
export function confirmBuy(data) {
    return request({
        url: `/v1/cart/confirmBuy?requestParam=${data.requestParam}&needDelDetailIds=${data.needDelDetailIds}`,
        method: 'post',
    })
}
//确认下单
export function buySuccess(data) {
    return request({
        url: '/v1/cart/buySuccess',
        method: 'post',
        data
    })
}
