import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import { useRouter } from 'vue-router';
import { ref } from "vue";
import Cart from "../components/shopping/Cart";
import ConfirmOrder from "../components/shopping/ConfirmOrder";
import OrderSuccess from "../components/shopping/OrderSuccess";
import Footer from "../components/home/Footer";
import config from "@/config/config";
export default {
  __name: 'ShoppingCart',
  setup(__props) {
    const contentKey = ref('cart');
    const router = useRouter();
    const clickTab = index => {
      console.log('index--', index);
      if (index == 1) {
        router.push('/home');
      } else if (index == 2) {
        router.push('/trade');
      } else if (index == 4) {
        window.open(config.wuliuBaseUrl, '_blank');
      }
    };
    //确认订单数据
    const orderData = ref();
    const clickRouter = (key, param) => {
      if (key == 'confirmOrder') {
        orderData.value = param;
      }
      contentKey.value = key;
    };
    return (_ctx, _cache) => {
      const _component_Header = _resolveComponent("Header");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Header, {
        onTabFn: clickTab
      }), contentKey.value == 'cart' ? (_openBlock(), _createBlock(_unref(Cart), {
        key: 0,
        onRouterFn: clickRouter
      })) : _createCommentVNode("", true), contentKey.value == 'confirmOrder' ? (_openBlock(), _createBlock(_unref(ConfirmOrder), {
        key: 1,
        onRouterFn: clickRouter,
        data: orderData.value
      }, null, 8, ["data"])) : _createCommentVNode("", true), contentKey.value == 'orderSuccess' ? (_openBlock(), _createBlock(_unref(OrderSuccess), {
        key: 2
      })) : _createCommentVNode("", true), _createVNode(_unref(Footer))], 64);
    };
  }
};