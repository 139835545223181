import { textualInfoByCode } from '../api/login';
export default {
  data() {
    return {
      protocolDialogVisible: false,
      // agree: false,
      protocolContent: '',
      originAll: [],
      title: ''
    };
  },
  props: {
    agree: Boolean
  },
  created() {
    this.gettextualInfoByCode();
  },
  mounted() {},
  computed: {
    //  isAgree(){
    //     return this.agree
    //  }
    // value: {
    //     get() {
    //         return this.modelValue
    //     },
    //     set(value) {
    //         console.log(value)
    //         this.$emit('update:modelValue', value)
    //     }
    // }
  },
  methods: {
    gettextualInfoByCode() {
      // EBUS-PROTOCOL 电商
      // WUL-PROTOCOL 物流
      // COLL-PROTOCOL 协同
      let data = {
        app: '电商',
        category: 1 // 1 登录 2 注册
      };
      textualInfoByCode(data).then(res => {
        if (!res || JSON.stringify(res) === '{}' || res.length < 1) {
          this.agreeFlag = true;
          this.isAgree = true;
          this.handleChange(true);
          return;
        }
        console.log(res);
        this.originAll = res;
      });
    },
    handleShow(item) {
      try {
        let content = item.content.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&').replace(/&quot;/g, '"').replace(/&apos;/g, "'");
        this.protocolContent = content;
        this.title = item.title;
        this.protocolDialogVisible = true;
      } catch (error) {
        console.log(error);
      }
    },
    handleChange(value) {
      this.$emit('updateAgree', value);
    }
  }
};