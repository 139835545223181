import request from '../utils/request';

//获取验证码
export function getKaptcha(param) {
    return request({
        url: `/v1/kaptcha?uuid=${param}`,
        method: 'get',
    })
}
// 用户登录
export function login(data) {
    return request({
        url: '/v1/login',
        method: 'post',
        data
    })
}
//退出登录
export function layoutLogin() {
    return request({
        url: `/v1/logout`,
        method: 'get',
    })
}
//注册
export function register(data) {
    return request({
        url: '/v1/user/register',
        method: 'post',
        data
    })
}
//发短信
export function sendText(data) {
    return request({
        url: '/v1/sms/sendVerifyCode',
        method: 'post',
        data
    })
}
//重置密码
export function resetPassword(data) {
    return request({
        url: '/v1/user/reset/password',
        method: 'post',
        data
    })
}
//校验用户名
export function validateUsername(data) {
    return request({
        url: '/v1/user/validate/name',
        method: 'post',
        data
    })
}
//校验手机号
export function validateMobile(data) {
    return request({
        url: '/v1/user/validate/mobile',
        method: 'post',
        data
    })
}
export function goNextFindPassword(data) {
    return request({
        url: '/v1/user/check/user',
        method: 'post',
        data
    })
}
//获取注册协议内容
export function getProtocalContent() {
    return request({
        url: `/v1/textual/infoByCode/SERVICE`,
        method: 'get',
    })
}
//企业认证
export function companyAuth(data) {
    return request({
        url: `/v1/user/company/auth`,
        method: 'post',
        data
    })
}
//受邀注册
export function invite(path,data) {
    return request({
        url:  `/v1/xcx-user/invite/${path}`,
        method: 'post',
        hideMessage:true,
        data
    })
}
export const textualInfoByCode = (params) => {
    return request({
    url: `https://dianshang-api.cpolar.cn/v1/textual/appList`,
      method: 'get',
      params
    })
  }