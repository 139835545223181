import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-73e8e83e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "electronicOrder"
};
const _hoisted_2 = ["innerHTML"];
import { useRouter, useRoute } from 'vue-router';
import { ref, onMounted } from "vue";
import Footer from "@/components/home/Footer";
import { getBuyerOrderByStatus, getOrderContract } from '../api/center';
import config from "@/config/config";
export default {
  __name: 'ElectronicOrder',
  setup(__props) {
    const route = useRoute();
    const orderId = route.query.orderId;
    const totalCount = ref(0);
    const content = ref();
    onMounted(async () => {
      let res2 = await getOrderContract(route.query.orderId);
      console.log('111--', res2);
      let arrEntities = {
        'lt': '<',
        'gt': '>',
        'nbsp': ' ',
        'amp': '&',
        'quot': '"'
      };
      const final = res2.replace(/&(lt|gt|nbsp|amp|quot);/ig, (all, t) => {
        return arrEntities[t];
      });
      content.value = final;
    });
    const router = useRouter();
    const clickTab = index => {
      console.log('index--', index);
      if (index == 1) {
        router.push('/home');
      } else if (index == 2) {
        router.push('/trade');
      } else if (index == 4) {
        window.open(config.wuliuBaseUrl, '_blank');
      }
    };
    return (_ctx, _cache) => {
      const _component_Header = _resolveComponent("Header");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Header, {
        onTabFn: clickTab
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
        class: "wrapper",
        innerHTML: content.value
      }, null, 8, _hoisted_2)]), _createVNode(_unref(Footer))], 64);
    };
  }
};