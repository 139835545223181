import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { useRoute } from 'vue-router';
export default {
  __name: 'App',
  setup(__props) {
    const route = useRoute();
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view, {
        key: _unref(route).fullPath
      });
    };
  }
};